import React, { useContext } from 'react';
import {
  Button, Container, Grid, Typography, useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { translationServiceContext } from '../services/provider';
import { TranslationService } from '../services/translationService';
import { GraphQlResponse } from '../models/gatsby/graphQlResponse';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    paddingBottom: theme.spacing(3),
  },
  titleContainer: {
    textAlign: 'center',
  },
  containerPaddingTop: {
    paddingTop: theme.spacing(2),
  },
  iconSize: {
    width: 120,
    height: 120,
  },
  smContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  general: {
    frontmatter: {
      title: string;
    };
  };
}

const NotFound: React.FC<GraphQlResponse<Props>> = (props) => {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();
  const theme: Theme = useTheme();

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <>
      <Helmet>
        <title>{`${translationService.translate('404_PAGE_NOT_FOUND')} | ${props.data.general.frontmatter.title}`}</title>
      </Helmet>
      <Container
        className={clsx(classes.container, classes.containerPaddingTop, isDownSm ? classes.smContainer : undefined)}
        disableGutters={isDownSm}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <CloudOffIcon fontSize="large" className={classes.iconSize} color="disabled" />
          </Grid>
          <Grid item className={classes.titleContainer}>
            <Typography variant="h5" gutterBottom>
              {translationService.translate('404_PAGE_NOT_FOUND')}
            </Typography>
            <Typography variant="body2">{translationService.translate('404_PAGE_NOT_FOUND_DESCRIPTION')}</Typography>
          </Grid>
          <Grid item>
            <Button component={Link} to="/nouveautes/" variant="contained" color="primary" disableElevation>
              {translationService.translate('404_SEE_OUR_NEW_PRODUCTS')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NotFound;

export const productPageDetailsQuery = graphql`
  query Get404PageData {
    general: markdownRemark(fileAbsolutePath: { regex: "/settings/general.md$/" }) {
      frontmatter {
        title
      }
    }
  }
`;
